import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import Entypo from 'react-native-vector-icons/Fonts/Entypo.ttf';

/**
 * Todo find better way to load fonts for icons
 */
const fontList = {
  FontAwesome,
  Entypo,
};

Object.keys(fontList).forEach(font => {
  const iconFontStyles = `@font-face {
    font-family: ${font};
    src: url(${fontList[font]});
  }`;

  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
});
